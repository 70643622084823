import React, {Component} from "react";
import {Row, Col} from "react-bootstrap";
import Ratio from "react-bootstrap/Ratio";

class SDE extends Component {
  render() {
    return (
      <Row id="sameDayEdit" className="justify-content-center">
        <Col className="text-center">
          <h1 className="sectionHeader">MPW SAME DAY EDIT</h1>
          <Ratio aspectRatio="16x9">
            <iframe
              title="MPW SDE"
              src="https://player.vimeo.com/video/322221204"
              frameBorder="0"
              allow="fullscreen"
            ></iframe>
          </Ratio>
          <p>
            <a href="https://vimeo.com/322221204">
              Michelle and Victor || MPW Same Day Edit
            </a>{" "}
            from <a href="https://vimeo.com/mpw">MPW Media Group</a> on{" "}
            <a href="https://vimeo.com">Vimeo</a>.
          </p>
        </Col>
      </Row>
    );
  }
}

export default SDE;
