import React, {Component} from "react";
import {Container} from "react-bootstrap";
import "./App.css";
import OurStory from "./OurStory";
import TopBanner from "./TopBanner";
import ImageCarousel from "./ImageCarousel";
import SDE from "./SDE";

class App extends Component {
  render() {
    return (
      <Container fluid>
        <TopBanner />
        <OurStory />
        <ImageCarousel />
        <SDE />
      </Container>
    );
  }
}

// TODO: create floating footer component
// TODO: create picture gallery
// FIXME: colored section headings
// TODO: find a better font for headings

export default App;
