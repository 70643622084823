import React, {useEffect} from 'react';

export default function Zachary529() {
  const giftPage = "https://go.fidelity.com/93dppw";

  useEffect(() => {
    document.title = "Redirecting to Fidelity..."
    window.location.href = giftPage;
  }, []);

  return (
    <div></div>
  );
}
