import React from 'react';
import {render} from 'react-dom';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import './index.css';
import Zachary529 from './routes/zachary-529';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';

// ReactDOM.render(<App />, document.getElementById('root'));
const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/zachary" element={<Zachary529 />} />
    </Routes>
  </BrowserRouter>,
  rootElement
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
