import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

class TopBanner extends Component {
  render() {
    return (
      <Row id="topBanner" className="justify-content-center">
        <Col className="text-center bg">
          <h1>
            MICHELLE + VICTOR
          </h1>
          <h2>
            02.16.19
          </h2>
        </Col>
      </Row>
    )
  }
}

export default TopBanner;