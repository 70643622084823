import React, {Component} from "react";
import {Row, Col, Image, Carousel} from "react-bootstrap";
import {carouselImages} from "./data";

class ImageCarousel extends Component {
  render() {
    const items = [];
    carouselImages.forEach(img => {
      items.push(
        <Carousel.Item key={img.id}>
          <Image src={img.path} className="d-block w-100" />
        </Carousel.Item>
      );
    });

    return (
      <Row id="imageCarousel">
        <Col>
          <Carousel pauseOnHover={true} controls={false} indicators={true}>
            {items}
          </Carousel>
        </Col>
      </Row>
    );
  }
}

export default ImageCarousel;
