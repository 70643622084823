import React, { Component } from "react";
import { Row, Col, Image } from "react-bootstrap";
import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import { GoCheck } from "react-icons/go";
import "react-vertical-timeline-component/style.min.css";
import { events } from "./data";

class TimelineEvent extends Component {
  render() {
    let image;
    if (this.props.image) {
      image = <Image src={this.props.image} fluid />;
    }
    return (
      <VerticalTimelineElement
        date={this.props.date}
        iconStyle={{ background: "rgba(247, 178, 170, 1)", color: "#fff" }}
        icon={<GoCheck />}
      >
        <h5 className="vertical-timeline-element-title">{this.props.title}</h5>
        <h6 className="vertical-timeline-element-subtitle text-muted">
          {this.props.location}
        </h6>
        {image}
      </VerticalTimelineElement>
    );
  }
}

class OurStory extends Component {
  render() {
    const items = [];
    events.forEach(event => {
      items.push(
        <TimelineEvent
          key={event.id}
          date={event.date}
          title={event.title}
          location={event.location}
          image={event.image}
        />
      );
    });

    return (
      <Row id="ourStory" className="justify-content-center">
        <Col md={6} lg={8} xl={12}>
          <h1 className="sectionHeader text-center">OUR STORY</h1>
          <VerticalTimeline>{items}</VerticalTimeline>
        </Col>
      </Row>
    );
  }
}

export default OurStory;
