export const events = [
  {
    id: 0,
    date: "07.11.2009",
    title: "Michelle + Victor first meet",
    location: "Clifton, NJ",
    image: "./img/img_vfm001.jpg"
  },
  {
    id: 1,
    date: "08.12.2011",
    title: "Michelle + Victor go to their first Yankee game together",
    location: "Bronx, NY"
  },
  {
    id: 2,
    date: "01.21.2012",
    title: "Sparks fly between Michelle + Victor",
    location: "Clifton, NJ",
    image: "./img/img_vmr001.jpg"
  },
  {
    id: 3,
    date: "06.14.2012",
    title: "Michelle + Victor go on their first date",
    location: "Edison, NJ"
  },
  {
    id: 4,
    date: "07.22.2012",
    title: "Michelle + Victor become a couple",
    location: "Montclair, NJ"
  },
  {
    id: 5,
    date: "04.28.2016",
    title: "Michelle + Victor buy a house",
    location: "Bridgewater, NJ",
    image: "./img/img_home001.jpg"
  },
  {
    id: 6,
    date: "01.17.2018",
    title: "Michelle + Victor get engaged",
    location: "Bridgewater, NJ",
    image: "./img/img_engaged001.jpg"
  },
  {
    id: 7,
    date: "02.16.2019",
    title: "Michelle + Victor get married",
    location: "Somerville, NJ"
  }
];

export const carouselImages = [
  {
    id: 0,
    path: "./img/MV_Eshoot0037.jpg"
  },
  {
    id: 1,
    path: "./img/MV_Eshoot0068.jpg"
  },
  {
    id: 2,
    path: "./img/MV_Eshoot0084.jpg"
  },
  {
    id: 3,
    path: "./img/MV_Eshoot0188.jpg"
  },
  {
    id: 4,
    path: "./img/MV_Eshoot0224.jpg"
  },
  {
    id: 5,
    path: "./img/MV_Eshoot0282.jpg"
  }
];
